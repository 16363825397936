import './App.css';
import logo from './assets/solwifhat.png';
import birdEyeLogo from './assets/birdeye.png';
import solscanLogo from './assets/solscan.png';
import twitterLogo from './assets/twitter.png';
import telegramLogo from './assets/telegram.webp';
import Confetti from 'react-confetti';

function App() {
  return (
    <div className="App">
      <div className='container'>
        <img
          className='logo'
          src={logo}
          alt='solwifhat'
        />
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
        />
        <a
          class='btn'
          href='https://jup.ag/swap/USDT-mSOL'
          target='_blank'
          rel="noreferrer"
        >
          Join the $HBDSOL party!
        </a>
        <div className='footer'>
          <a
            className='link'
            href='https://birdeye.so/token/HBdSoLCR8xBjVrzNScSjHW14JjT7Zt7UzTBoMQdFnfhs?chain=solana'
            target='_blank'
            rel="noreferrer"
          >
            <img
              className='footer-icon'
              src={birdEyeLogo}
              height={24}
              alt='birdeye'
            />
            Bird eye
          </a>

          <a
            className='link'
            href='https://solscan.io/account/HBdSoLCR8xBjVrzNScSjHW14JjT7Zt7UzTBoMQdFnfhs'
            target='_blank'
            rel="noreferrer"
          >
            <img
              className='footer-icon'
              src={solscanLogo}
              height={24}
              alt='solscan'
            />
            Contract
          </a>

          <a
            className='link'
            href='https://t.me/hbdsol2024'
            target='_blank'
            rel="noreferrer"
          >
            <img
              className='footer-icon'
              src={telegramLogo}
              height={24}
              alt='birdeye'
            />
            Telegram
          </a>


          <a
            className='link'
            href='https://twitter.com/HBDSOL2024'
            target='_blank'
            rel="noreferrer"
          >
            <img
              className='footer-icon'
              src={twitterLogo}
              height={24}
              alt='twitter'
            />
            Twitter
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
